import React, { FC } from "react";
import { useWidgetDependencies } from "../utils/WidgetUtils";

const WithdrawalWidget: FC = () => {
  useWidgetDependencies("ma4b-withdrawal");

  return (
    <div
      id="ma4b-withdrawal"
      data-key="5a919282-8803-47b4-a82d-479fa20d5bfd"
      data-layout="card-right"
      data-withdrawal-flow-url="https://engine.ma4b.io/flow/widget-withdrawal"
      data-global-withdrawal-min-amount-url="https://engine.ma4b.io/flow/get-global-withdrawal-minimum-amount"
      data-has-wallet-transactions-url="https://engine.ma4b.io/flow/has-wallet-transactions"
      data-get-payment-provider-accounts-url="https://engine.ma4b.io/flow/get-payment-provider-accounts"
      data-create-user-confirmation-url="https://engine.ma4b.io/flow/user-confirmation-authenticated"
      data-activate-nuvei="true"
      data-activate-paypal="true"
      data-activate-paysafe="true"
      data-activate-skrill="true"
      data-activate-trustly="true"
      data-activate-truevo="false"
    />
  );
};

export default WithdrawalWidget;
