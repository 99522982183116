import React, { FC } from "react";
import { useWidgetDependencies } from "../utils/WidgetUtils";

const MyProfileWidget: FC = () => {
  useWidgetDependencies("ma4b-my-profile");

  return (
    <div
      id="ma4b-my-profile"
      data-key="5a919282-8803-47b4-a82d-479fa20d5bfd"
      data-layout="card-right"
      data-create-identity-trait-change-request-url="https://engine.ma4b.io/flow/create-identity-trait-change-request-authenticated"
      data-delete-identity-trait-change-request-url="https://engine.ma4b.io/flow/delete-identity-trait-change-request-authenticated"
    />
  );
};

export default MyProfileWidget;
