import * as React from "react";
import {FC} from "react";

type WidgetProps = {
    height: "fit" | "full"
    widget?: React.ReactNode
}

const Widget: FC<WidgetProps> = ({height, widget}) => {
    return (
        <div
            className={`w-10/12 max-h-full overflow-y-auto rounded-l-lg border-y border-l border-zinc-100 dark:border-white bg-white dark:bg-zinc-900 h-${height}`}>
            {widget}
        </div>

    )
}

export default Widget;