import React, {FC} from "react";
import {useWidgetDependencies} from "../utils/WidgetUtils";

const SettingsWidget: FC = () => {
    useWidgetDependencies("ma4b-settings");

    return (
        <div
            id="ma4b-settings"
            data-key="5a919282-8803-47b4-a82d-479fa20d5bfd"
            data-layout="card-right"
            data-get-identity-url="https://engine.ma4b.io/flow/get-identity-authenticated"
            data-set-identity-settings-url="https://engine.ma4b.io/flow/set-identity-settings-authenticated"
        />
    )
};

export default SettingsWidget;