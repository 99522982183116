import React, {FC} from "react";
import NavigationWidget from "./NavigationWidget";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import AccountWidget from "./AccountWidget";
import DepositWidget from "./DepositWidget";
import MyBetsWidget from "./MyBetsWidget";
import NotFound from "./NotFound";
import WithdrawalWidget from "./WithdrawalWidget";
import LimitsWidget from "./LimitsWidget";
import MyProfileWidget from "./MyProfileWidget";
import SettingsWidget from "./SettingsWidget";
import ChangePasswordWidget from "./ChangePasswordWidget";
import Widget from "./Widget";

const router = createBrowserRouter([
    {
        path: "/account",
        element: <Widget height={"fit"} widget={<AccountWidget/>}/>,
    },
    {
        path: "/my-bets",
        element: <Widget height={"fit"} widget={<MyBetsWidget/>}/>,
    },
    {
        path: "/deposits",
        element: <Widget height={"fit"} widget={<DepositWidget/>}/>,
    },
    {
        path: "/withdrawal",
        element: <Widget height={"full"} widget={<WithdrawalWidget/>}/>,
    },
    {
        path: "/limits",
        element: <Widget height={"full"} widget={<LimitsWidget/>}/>,
    },
    {
        path: "/my-profile",
        element: <Widget height={"fit"} widget={<MyProfileWidget/>}/>,
    },
    {
        path: "/settings",
        element: <Widget height={"fit"} widget={<SettingsWidget/>}/>,
    },
    {
        path: "/change-password",
        element: <Widget height={"fit"} widget={<ChangePasswordWidget/>}/>,
    },
    {
        path: "/",
        element: <Widget height={"fit"} widget={<AccountWidget/>}/>,
    },
    {
        path: "*",
        element: <Widget height={"fit"} widget={<NotFound/>}/>,
    },
]);


const WidgetWithNavigation: FC = () => {
    return (
        <div className="flex-grow flex overflow-hidden overflow-y-auto py-8">
            {/* Sticky Navigation */}
            <div className="sticky z-10 top-0 w-2/12 self-start">
                <NavigationWidget/>
            </div>

            {/* Widget */}
            <React.StrictMode>
                <RouterProvider router={router}/>
            </React.StrictMode>
        </div>
    )
}

export default WidgetWithNavigation;