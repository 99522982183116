import React, {FC} from "react";
import {useWidgetDependencies} from "../utils/WidgetUtils";

const NavigationWidget: FC = () => {
    useWidgetDependencies("ma4b-navigation");

    return (
        <div className="w-full -mt-4">
            <div id="ma4b-navigation"
                 data-key="5a919282-8803-47b4-a82d-479fa20d5bfd"
                 data-layout="standalone"
                 data-href-account="/account"
                 data-href-my-bets="/my-bets"
                 data-href-deposits="/deposits"
                 data-href-withdrawal="/withdrawal"
                 data-href-limits="/limits"
                 data-href-my-profile="/my-profile"
                 data-href-settings="/settings"
                 data-href-change-password="/change-password"
            />
        </div>
    )
};

export default NavigationWidget;