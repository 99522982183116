import React, {FC} from "react";
import {useWidgetDependencies} from "../utils/WidgetUtils";

const LimitsWidget: FC = () => {
    useWidgetDependencies("ma4b-limits");

    return (
        <div
            id="ma4b-limits"
            data-key="5a919282-8803-47b4-a82d-479fa20d5bfd"
            data-layout="card-right"
            data-support-email="support.email@example.com"
            data-support-phone-number="+49123456789"
            data-support-url="https://example.com/support"
            data-support-start-time="08:00 AM"
            data-support-end-time="06:00 PM"
        />
    )
};

export default LimitsWidget;